
import { useEffect } from 'react';
import './App.css';
import Layout from './components/Layout';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  AOS.init();

  useEffect(() => {
    var Tawk_API = Tawk_API || {}
    var Tawk_LoadStart = new Date()

    const script = document.createElement("script")
    script.async = true

    script.src = "https://embed.tawk.to/677bb16daf5bfec1dbe74647/1igtiq587"
    script.charset = "UTF-8"
    script.setAttribute("crossorigin", "*")

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
  return (
    <div>
      <Layout />
    </div>
  );
}

export default App;
